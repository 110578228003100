<script>
	  
	export let loading = false
	  
</script>

<style>
    img {
        max-width: 180px;
        border-radius: 1000px;
        overflow: hidden;
    }
</style>

{#if loading}
    <div class="items-center">
        <div class="animate__animated animate__flipInY animate__infinite">
            <img src="/images/brand_transparent.png" alt="YaVaEnvio">
        </div>
    </div>
{/if}