<script>

    import QueryString from 'query-string'
    import { querystring, replace } from 'svelte-spa-router'

    import Container from '../$layouts/container.layout.svelte'
    import Button from '../$components/button.svelte'

    const query = QueryString.parse($querystring, {parseBooleans: true})
    console.log(query)

</script>

<style>
    img {
        max-width: 200px !important;
    }
</style>

<Container>
    <div class="items-center">
        <img src="/images/tiendanube.svg" alt="Tiendanube">
    </div>
    <div class="title items-center">Asistente de integración de YaVaEnvio</div>
    <div class="subtitle items-center">Tu cuenta ha sido { query.password != 'NA'? 'creada:' : 'recuperada:' }</div>
    <br>
    <div class="subtitle">Tienda: <b>{ query.store }</b></div>
    <div class="subtitle">Email: <b>{ query.email }</b></div>
    {#if query.password && query.password != 'NA'}
        <div class="subtitle">Contraseña: <b>{ query.password }</b></div>
    {/if}
    <small>Guarda estos datos en un lugar seguro. Puedes cambiar ambos más adelante.</small>
    <hr>
    <div class="items-center">
        <Button on:click={() => replace('#/')} text="Ingresar al panel de YaVaEnvio" color="primary" />
    </div>
</Container>