<script>

	import Moment from 'moment'

	import GraphicsOptions from './component.options.svelte'
	import GraphicsShipmentsTotal from './component.shipments.total.svelte'
	import GraphicsShipmentsInvoice from './component.shipments.invoice.svelte'
    import GraphicsShipmentsStatus from './component.shipments.status.svelte'

	let count = 1
	let query = {
        dateStart: Moment().startOf('month').startOf('day').toDate(),
        dateEnd: Moment().endOf('month').endOf('day').toDate(),
		rangeType: 'days'
    }

	function changeQuery(ev) {
		query = ev.detail
		count = count + 1
	}

</script>

<div class="columns">
	<div class="column">
		<GraphicsOptions on:submit={changeQuery} {query} />
    </div>
    <div class="column">
		<GraphicsShipmentsStatus bind:query={query} bind:count={count} />
    </div>
</div>

<div class="columns">
	<div class="column">
		<GraphicsShipmentsTotal bind:query={query} bind:count={count} />
	</div>
	<div class="column">
		<GraphicsShipmentsInvoice bind:query={query} bind:count={count} />
	</div>
</div>