<script>

    import { link } from 'svelte-spa-router'
    import { usersChatStore, userChatStore, userStore } from '../stores'

    import UsersService from '../$services/users.service'
    import Message from '../$components/message.svelte'

    let loading = false

    getUsersChat()

    async function getUsersChat() {

        loading = true
        const resp = await UsersService.getUsersChat()
        loading = false

        if(!resp.success)
            return toast({ message: resp.error.message, type: "is-danger" })

        usersChatStore.set(resp.data)
    }

    function setUserChat(user) {
        userChatStore.set(user)
    }

</script>

<style>
    .media {
        padding: 16px;
    }

    .media:hover {
        background: #FEFEFE;
        cursor: pointer;
    }

    .card {
        overflow-y: auto;
    }

    .card.height {
        height: 78%;
    }

    .active {
        background: #FEFEFE;
    }
</style>

<Message show={!loading && !$usersChatStore.length} />

{#if !loading && $usersChatStore.length}
    <div class="height">
        {#each $usersChatStore as user}
            <div on:click={() => setUserChat(user)} class="media" class:active={ $userChatStore && $userChatStore._id === user._id }>
                <div class="media-left">
                    <figure class="image is-64x64">
                        {#if user.avatar}
                            <img src="{ user.avatar }" alt="User" />
                        {:else}
                            <img src="https://bulma.io/images/placeholders/96x96.png" alt="User" />
                        {/if}
                    </figure>
                </div>
                <div class="media-content">
                    <p class="title is-5">{ user.alias || user.name }</p>
                    <p class="subtitle is-6">
                        <a href="/posts/user/{ user._id }" use:link>Ver perfil</a>
                    </p>
                </div>
            </div>
        {/each}
    </div>
{/if}
<br>
<div class="media">
    <div class="media-left">
        <figure class="image is-64x64">
            {#if $userStore.avatar}
                <img src="{ $userStore.avatar }" alt="User" />
            {:else}
                <img src="https://bulma.io/images/placeholders/96x96.png" alt="User" />
            {/if}
        </figure>
    </div>
    <div class="media-content">
        <div class="items-space">
            <div>
                <p class="title is-5">{ $userStore.alias || $userStore.name }</p>
                <p class="subtitle is-6">Activo</p>
            </div>
            <a href="/settings" use:link>
                <span class="icon is-large pointer"><i class="fas fa-lg fa-cog"></i></span>
            </a>
        </div>
    </div>
</div>