<script>

    import { modalStore } from '../stores.js'

    import Modal from '../$components/modal.svelte'
    import CreditComponent from './component.credit.svelte'

    $: show = $modalStore === 'credit_modal'
    
</script>


<Modal title="Recarga" {show}>

    <CreditComponent />
    
</Modal>