<script>

    import { replace, location } from 'svelte-spa-router'
    import { userStore, modalStore } from '../stores'

    import StorageService from '../$services/storage.service'
    
    import Modal from '../$components/modal.id.svelte'
    import Wallet from '../wallets/component.wallet.svelte'
    import StripeCheckout from '../stripe/component.checkout.svelte'

    function onLogout() {
        StorageService.deleteItem('user')
        StorageService.deleteItem('access')
        replace('#/')
    }

    function showView(name) {
        replace(name)
        modalStore.set(null)
    }
  
</script>

<style>

    .mobile {
        display: none;
    }

    @media (max-width: 500px) {
        .web {
            display: none;
        }
        .mobile {
            display: initial;
        }
    }
</style>

<!-- svelte-ignore a11y-missing-attribute -->

<section class="hero" style="background: #222;">
    <div class="hero-body">
        <div class="items-space">
            <img src="/images/brand.png" alt="yavaenvio" style="max-width: 150px; border-radious">
            <div class="items-end mobile">
                <button on:click={() => modalStore.set('menu_mobile')} class="button">
                    <span class="icon"><i class="fas fa-bars"></i></span>
                </button>
            </div>
            <div class="items-end web">
                <Wallet />
                <div class="space-left" style="color: #FEFEFE;">
                    <span class="icon"><i class="fas fa-user"></i></span>
                    <span>{ $userStore.name }</span>
                </div>
                <div on:click={() => onLogout()} class="space-left" style="color: #FEFEFE;">
                    <span class="icon"><i class="fas fa-sign-out-alt"></i></span>
                    <span>Salir</span>
                </div>
            </div>
        </div>
    </div>
    <div class="hero-foot web">
        <nav class="tabs is-boxed is-right">
            <div class="container">
                <ul>
                    <li class:is-active={ $location === '/home' } >
                        <a on:click={() => showView('#/home')} style={ $location === '/home'? 'color: #222 !important;' : 'color: #FEFEFE;' }>
                            <span class="icon"><i class="fas fa-home"></i></span>
                            <span>Inicio</span>
                        </a>
                    </li>
                    <li class:is-active={ $location === '/quotations' } >
                        <a on:click={() => showView('#/quotations')} style={ $location === '/quotations'? 'color: #222 !important;' : 'color: #FEFEFE;' }>
                            <span class="icon"><i class="fas fa-calculator"></i></span>
                            <span>Cotizador</span>
                        </a>
                    </li>
                    <li class:is-active={ $location === '/shipments' } >
                        <a on:click={() => showView('#/shipments')} style={ $location === '/shipments'? 'color: #222 !important;' : 'color: #FEFEFE;' }>
                            <span class="icon"><i class="fas fa-truck"></i></span>
                            <span>Envíos</span>
                        </a>
                    </li>
                    <li class:is-active={ $location === '/orders' } >
                        <a on:click={() => showView('#/orders')} style={ $location === '/orders'? 'color: #222 !important;' : 'color: #FEFEFE;' }>
                            <span class="icon"><i class="fas fa-clipboard-list"></i></span>
                            <span>Ordenes</span>
                        </a>
                    </li>
                    <li class:is-active={ $location === '/settings' } >
                        <a on:click={() => showView('#/settings')} style={ $location === '/settings'? 'color: #222 !important;' : 'color: #FEFEFE;' }>
                            <span class="icon"><i class="fas fa-user-cog"></i></span>
                            <span>Configuración</span>
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
</section>

<!-- svelte-ignore a11y-missing-attribute -->

<Modal id="menu_mobile">
    <div class="items-space">
        <div class="space-left">
            <span class="icon"><i class="fas fa-user"></i></span>
            <span>{ $userStore.name }</span>
        </div>
        <div>
            <Wallet />
        </div>
    </div>
    <hr>
    <aside class="menu">
        <ul class="menu-list">
            <li>
                <a on:click={() => showView('#/home')} class:is-active={ $location === '/home' }>
                    <span class="icon"><i class="fas fa-home"></i></span>
                    <span>Inicio</span>
                </a>
            </li>
            <li>
                <a on:click={() => showView('#/quotations')} class:is-active={ $location === '/quotations' }>
                    <span class="icon"><i class="fas fa-calculator"></i></span>
                    <span>Cotizador</span>
                </a>
            </li>
            <li>
                <a on:click={() => showView('#/shipments')} class:is-active={ $location === '/shipments' }>
                    <span class="icon"><i class="fas fa-truck"></i></span>
                    <span>Envíos</span>
                </a>
            </li>
            <li>
                <a on:click={() => showView('#/orders')} class:is-active={ $location === '/orders' }>
                    <span class="icon"><i class="fas fa-clipboard-list"></i></span>
                    <span>Ordenes</span>
                </a>
            </li>
            <li>
                <a on:click={() => showView('#/settings')} class:is-active={ $location === '/settings' }>
                    <span class="icon"><i class="fas fa-user-cog"></i></span>
                    <span>Configuración</span>
                </a>
            </li>
        </ul>
    </aside>
    <hr>
    <div class="items-center">
        <div on:click={() => onLogout()} class="space-left">
            <span class="icon"><i class="fas fa-sign-out-alt"></i></span>
            <span>Salir</span>
        </div>
    </div>
</Modal>

<Modal id="wallet_payment" title="Agregue saldo">
    <StripeCheckout />
</Modal>