import Superagent from 'superagent'
import Storage from './storage.service'
import $logout from './$logout'

const { HOST_API } = _CONSTANTS_

export default {
    getTransactions
}

function getTransactions(userId) {
    return new Promise((resolve, reject) => {
        Superagent
            .get(`${HOST_API}/transactions`)
            .set('access', Storage.getItem('access'))
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}