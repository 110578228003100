<script>

    import { createEventDispatcher } from 'svelte'
    const dispatch = createEventDispatcher()

    export let provider = ""

    function onSelect() {
        dispatch('select')
    }

</script>

<div class="field">
    <label for="" class="label">Proveedor</label>
    <div class="control has-icons-left">
        <span class="icon"><i class="fas fa-truck"></i></span>
        <div class="select ">
            <select bind:value={provider} on:change={onSelect} >
                <option value="">Proveedor</option>
                <option value="Estafeta">Estafeta</option>
                <option value="Redpack">Redpack</option>
                <option value="DFExpress">DFExpress</option>
                <option value="AMPM">AMPM</option>
                <option value="Paquetexpress">Paquetexpress</option>
                <option value="DHL">DHL</option>
                <option value="FedEx">FedEx</option>
                <option value="99Minutos">99Minutos</option>
                <option value="Treggo">Treggo</option>
            </select>
        </div>
    </div>
</div>