<script>

    import { toast } from 'bulma-toast'
    import { replace } from 'svelte-spa-router'
    import { shipmentStore, shipmentsStore, modalStore, notifyStore } from '../stores'

    import ShipmentsService from '../$services/shipments.service'
    import OrdersService from '../$services/orders.service'
    import NumeralMethods from '../$methods/numeral.methods'
    import DateMethod from '../$methods/date.methods'

    import Delete from '../$components/delete.svelte'
    import Value from '../$components/value.svelte'

    import LocationDetails from '../locations/component.location.details.svelte'
    import PackageDetails from '../packages/component.package.details.svelte'

    const { HOST_APP } = _CONSTANTS_
    const { BRAND } = _CONSTANTS_

    let loading = false
    let data = {}

    let referenceInput = null
    let element = null

    async function cancelShipment() {

        loading = true
        const resp = await ShipmentsService.cancelShipment($shipmentStore._id, data)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        shipmentStore.set(resp.data)
        shipmentsStore.replace(resp.data)

        toast({message: 'Guía cancelada', type: 'is-success'})
        notifyStore.set('wallet_updated')
    }

    function onCopy() {
        referenceInput.select()
        referenceInput.setSelectionRange(0, 99999)
        document.execCommand("copy")
        toast({message: 'Link copiado', type: 'is-success'})
    }

    async function createOrder() {

        const data = {
            pickup: $shipmentStore.order.pickup,
            delivery: $shipmentStore.order.delivery,
            package: $shipmentStore.order.package
        }

        loading = true
        const resp = await OrdersService.createOrder(data)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        modalStore.close()

        toast({message: 'Orden duplicada', type: 'is-success'})
        replace('#/orders')
    }

    async function sendShipmentEmail() {

        loading = true
        const resp = await ShipmentsService.sendShipmentEmail($shipmentStore._id, {email: $shipmentStore.order.delivery.email})
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        toast({message: 'Correo enviado', type: 'is-success'})
    }

    async function createReturnOrder() {

        let tempOrderDelivery = $shipmentStore.order.delivery
        let tempOrderPickup = $shipmentStore.order.pickup

        const data={
            pickup: tempOrderDelivery,
            delivery: tempOrderPickup,
            package: $shipmentStore.order.package
        }

        loading = true
        const resp = await OrdersService.createOrder(data)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        modalStore.close()

        toast({message: 'Orden con retorno', type: 'is-success'})
        replace('#/orders')
    }

</script>

<a href="/" bind:this={element} style="display: none;">*</a>

{#if $shipmentStore}
    <div class="columns">
        <div class="column">
            <div>
                <div class="title">{ $shipmentStore.provider }</div>
                <div class="subtitle">{ $shipmentStore.service }</div>
                <small>Guia</small>
                <div class="subtitle">{ $shipmentStore.guide }</div>
                <small>Código</small>
                <div class="subtitle">{ $shipmentStore.code }</div>
                <small>Número de orden</small>
                <div class="subtitle"><b>#{ $shipmentStore.order.number }</b></div>
                <small>Entrega</small>
                <div class="subtitle"><b>{ $shipmentStore.order.isOccurs? 'Ocurre' : 'Domicilio' }</b></div>
                {#if $shipmentStore.order.service }
                    <small>Servicio en tienda</small>
                    <div class="subtitle">{ $shipmentStore.order.service.code }</div>
                {/if}
                <small>Fecha</small>
                <div class="subtitle">{ DateMethod.dateTimeLarge($shipmentStore.created) }</div>
                {#if $shipmentStore.order.tag}
                    <small>Etiqueta</small>
                    <div><span class="tag is-primary">{ $shipmentStore.order.tag }</span></div>
                {/if}
            </div>
        </div>
        <div class="column">
            <div class="title">Precio final</div>
            <div class="subtitle">${$shipmentStore.pricing.finalPrice}</div>
            <br>
            {#if $shipmentStore.pricing.credentialType === BRAND}
                <div class="items-space">
                    <div>Costo envío</div>
                    <div>${ $shipmentStore.pricing.shipmentPrice }</div>
                </div>
                <div class="items-space">
                    <div>Costo seguro</div>
                    <div>${ $shipmentStore.pricing.insurancePrice }</div>
                </div>
                <div class="items-space">
                    <div>Costo extra</div>
                    <div>${ $shipmentStore.pricing.aggregatePrice }</div>
                </div>
            {/if}
            {#if $shipmentStore.pricing.credentialType === 'user'}
                <div class="items-space">
                    <div>Comisión</div>
                    <div>${ $shipmentStore.pricing.commissionPrice }</div>
                </div>
            {/if}
            {#if $shipmentStore.states}
                <br>
                {#if $shipmentStore.canceledDate}
                    <div class="items-space">
                        <div>Cancelada</div>
                        <div>{ DateMethod.dateLarge($shipmentStore.canceledDate) }</div>
                    </div>
                {/if}
                {#if $shipmentStore.refundDate}
                    <div class="items-space">
                        <div>Reembolsada</div>
                        <div>{ DateMethod.dateLarge($shipmentStore.refundDate) }</div>
                    </div>
                {/if}
                {#if $shipmentStore.chargeDate}
                    <div class="items-space">
                        <div>Cargo Uso</div>
                        <div>{ DateMethod.dateLarge($shipmentStore.chargeDate) }</div>
                    </div>
                {/if}
            {/if}
            <div class="tags">
                {#if !$shipmentStore.isDeliveryHome}
                    <span class="tag is-danger">Sin entrega a domicilio</span>
                {/if}
                {#if $shipmentStore.isCoverageCost}
                    <span class="tag is-danger">Zona extendida</span>
                {/if}
            </div>
            {#if $shipmentStore.status != 'canceled'}
                <div class="columns">
                    <div class="column">
                        <a href="{ $shipmentStore.document }" class="button is-primary is-fullwidth" target="_blank">
                            <span class="icon"><i class="fas fa-file-pdf"></i></span>
                            <span>Descargar guía</span>
                        </a>
                    </div>
                </div>
            {/if}
            {#if $shipmentStore.type === 'international' && $shipmentStore.provider === 'FedEx'}
                <div class="items-center space-top">
                    <a href="/files/fedex_commercial_invoice.pdf" target="_blank">
                        <span class="icon"><i class="fas fa-file"></i></span>
                        <span>Formato factura comercial</span>
                    </a>
                </div>
            {/if}
            <div class="columns">
                <div class="column">
                    <button class="button is-fullwidth">
                        <span on:click={() => modalStore.open('tracking', 'modal', $shipmentStore)} class="icon"><i class="fas fa-map-marker-alt"></i></span>
                        <span>Rastrear envío</span>
                    </button>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <button on:click={createOrder} class="button is-fullwidth">
                        <span class="icon"><i class="fas fa-copy"></i></span>
                        <span>Duplicar órden</span>
                    </button>
                </div>
                <div class="column">
                    <button on:click={createReturnOrder} class="button is-fullwidth">
                        <span class="icon"><i class="fas fa-retweet"></i></span>
                        <span>Órden de retorno</span>
                    </button>
                </div>
            </div>
            {#if $shipmentStore.status != 'canceled' && $shipmentStore.tracking.length <= 1}
                <div class="columns">
                    <div class="column">
                        <Delete on:confirm={cancelShipment} text="Cancelar envío" icon="ban" />
                    </div>
                </div>
            {/if}
        </div>
    </div>
    {#if $shipmentStore.type === 'international' && $shipmentStore.provider === 'FedEx'}
        <br>
        <div class="columns">
            <div class="column">
                <article class="message">
                    <div class="message-body">
                        ❗ Recuerda descargar y llenar el <strong>formato de factura comercial</strong> y anexar la <strong>factura</strong> que avala el costo de los productos
                    </div>
                </article>
            </div>
        </div>
    {/if}
    <br>
    {#if $shipmentStore.status != 'canceled'}
        <br>
        <div class="columns">
            <div class="column">
                <div class="title">Link de rastreo</div>
                <div class="subtitle">
                    <div class="items-space-around">
                        <input bind:this={ referenceInput } value={`${HOST_APP}/#/trackings/${ $shipmentStore.code }`} class="input space-right" readonly>
                        <button on:click={onCopy} class="button" data-tooltip="Copiar link">
                            <span class="icon"><i class="fas fa-copy"></i></span>
                        </button>
                    </div>
                    <small>Puedes compartir el link de rastreo</small>
                </div>
            </div>
        </div>
        <br>
        <div class="columns">
            <div class="column">
                <div class="title">Reenvíar correo</div>
                <div class="subtitle">
                    <div class="items-space-around">
                        <input bind:value={ $shipmentStore.order.delivery.email } class="input space-right">
                        <button on:click={ sendShipmentEmail } class="button">
                            <span class="icon"><i class="fas fa-paper-plane"></i></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    {/if}
    <br>
    <div class="columns">
        <div class="column">
            <div class="title">Origen</div>
            <div style="padding-top: 0">
                <LocationDetails location={ $shipmentStore.order.pickup } />
                {#if $shipmentStore.order.pickup.address}
                    <div class="space-top">
                        <a target="_blank" href="https://www.google.com/maps/search/?api=1&query={$shipmentStore.order.pickup.position.lat},{ $shipmentStore.order.pickup.position.lng }">
                            Google Maps
                        </a>
                    </div>
                    <div><small>{$shipmentStore.order.pickup.address}</small></div>
                {/if}
            </div>
        </div>
        <div class="column">
            <div class="title">Destino</div>
            <div style="padding-top: 0">
                <LocationDetails location={ $shipmentStore.order.delivery } />
                {#if $shipmentStore.order.delivery.address}
                    <div class="space-top">
                        <a target="_blank" href="https://www.google.com/maps/search/?api=1&query={$shipmentStore.order.delivery.position.lat},{ $shipmentStore.order.delivery.position.lng }">
                            Google Maps
                        </a>
                    </div>
                    <div><small>{$shipmentStore.order.delivery.address}</small></div>
                {/if}
            </div>
        </div>
    </div>
    <br>
    <br>
    <div class="columns">
        <div class="column">
            <div class="title">Paquete</div>
            <div style="padding-top: 0">
                <PackageDetails ipackage={ $shipmentStore.order.package } />
            </div>
        </div>
    </div>
    <br>
    {#if $shipmentStore.order.merchandises.length}
        <br>
        <div class="columns">
            <div class="column">
                <div class="title">Mercancias</div>
                {#each $shipmentStore.order.merchandises as merchandise}
                    <div class="columns">
                        <div class="column">
                            <div class="columns">
                                <div class="column">
                                    <Value label="Producto" value={merchandise.product.name} />
                                </div>
                                <div class="column">
                                    <Value label="Código" value={merchandise.product.code} />
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <Value label="Unidad medida" value={merchandise.unit.name} />
                                </div>
                                <div class="column">
                                    <Value label="Código" value={merchandise.unit.code} />
                                </div>
                            </div>
                        </div>
                        <div class="column">
                            <div class="columns">
                                <div class="column">
                                    <Value label="Items" value={merchandise.items} />
                                </div>
                                <div class="column">
                                    <Value label="Peso" value={merchandise.weight+'KG'} />
                                </div>
                                <div class="column">
                                    <Value label="Valor" value={ NumeralMethods.cash(merchandise.value) } />
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <Value label="Descripción" value={merchandise.description} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                {/each}
            </div>
        </div>
    {/if}
{/if}