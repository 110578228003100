<script>

    import { modalStore } from '../stores'

    import Modal from '../$components/modal.svelte'
    import IntegrationShopifyComponent from './component.shopify.svelte'
    import IntegrationWoocommerceComponent from './component.woocommerce.svelte'
    import IntegrationVtexComponent from './component.vtex.svelte'
    import IntegrationTiendanubeComponent from './component.tiendanube.svelte'
    import IntegrationOdooComponent from './component.odoo.svelte'

    $: show = $modalStore === 'integration_create_modal'

    export let storeType = null

</script>

<style>

    .items-center {
        max-height: 180px;
        margin: 8px;
    }

    .items-center img {
        max-width: 100px;
        cursor: pointer;
    }

    @media (max-width: 500px) {
        .items-space-around {
            display: grid;
        }
    }

</style>

<Modal title="Integrar tienda" {show} >

    {#if !storeType}
        <div class="items-space-around">
            <div class="items-center">
                <img on:click={() => storeType='woocommerce'} src="images/woocommerce.svg" alt="Brand">
            </div>
            <div class="items-center">
                <img on:click={() => storeType='shopify'} src="images/shopify.svg" alt="Brand">
            </div>
            <div class="items-center">
                <img on:click={() => storeType='tiendanube'} src="images/tiendanube.svg" alt="Brand">
            </div>
            <div class="items-center">
                <img on:click={() => storeType='odoo'} src="images/odoo.svg" alt="Brand">
            </div>
        </div>
    {/if}

    {#if storeType == 'shopify'}
        <IntegrationShopifyComponent on:close={() => storeType = null} />
    {/if}

    {#if storeType == 'woocommerce'}
        <IntegrationWoocommerceComponent on:close={() => storeType = null} />
    {/if}

    {#if storeType == 'vtex'}
        <IntegrationVtexComponent on:close={() => storeType = null} />
    {/if}

    {#if storeType == 'tiendanube'}
        <IntegrationTiendanubeComponent on:close={() => storeType = null} />
    {/if}

    {#if storeType == 'odoo'}
        <IntegrationOdooComponent on:close={() => storeType = null} />
    {/if}

</Modal>