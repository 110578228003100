<script>

    import { createEventDispatcher } from 'svelte'

    const dispatch = createEventDispatcher()

    export let label = null
    export let icon = null
    export let min = null
    export let max = null
    export let value = null
    export let placeholder = null
    export let iconType = 'fa'
    export let step = null
    
    export let required = true
    export let readonly = false

    export let whitesmoke = false

</script>

<style>
    .class-whitesmoke {
        color: #FEFEFE;
    }
</style>

<div class="field">
    {#if label}
        <label for="" class="label" class:class-whitesmoke={ whitesmoke}>{ label }</label>
    {/if}
    <div class="control" class:has-icons-left={ icon }>
        {#if icon}
            <span class="icon"><i class="fas { iconType }-{ icon }"></i></span>
        {/if}
        <input bind:value={ value } on:keyup={ e => e.code === 'Enter' && dispatch('enter') } class="input" type="number" {step} {min} {max} { readonly } { placeholder } { required } >
    </div>
</div>