<script>
    import { userStore } from '../stores'

    import Menu from '../$layouts/menu.layout.svelte'
    import Container from '../$layouts/container.layout.svelte'
    import Tabs from '../$components/tabs.svelte'

    import PrepaidsComponent from '../prepaids/component.prepaids.svelte'
    import PrepaidModal from '../prepaids/modal.prepaid.svelte'

    import ChargesComponent from '../charges/component.charges.svelte'
    import ChargeModal from '../charges/modal.charge.svelte'

    import CreditsComponent from '../credits/component.credits.svelte'
    import CreditModal from '../credits/modal.credit.svelte'

    import TransactionsComponent from '../transactions/component.transactions.svelte'
    import TransactionModal from '../transactions/modal.transaction.svelte'

    import AccountsComponent from '../accounts/component.accounts.svelte'

    let selected = 'credits'

    const tabsOptions = [
        {key: 'credits', name: 'Recargas', icon: 'credit-card'},
        {key: 'accounts', name: 'Cuentas', icon: 'file-invoice-dollar'},
        {key: 'prepaids', name: 'Guías prepago', icon: 'shopping-cart'},
        {key: 'charges', name: 'Cargos / Reembolsos', icon: 'dollar-sign'},
        {key: 'transactions', name: 'Transacciones', icon: 'clipboard'},
    ]

</script>

<Menu />

<Container>

    <Tabs bind:selected options={tabsOptions} />

    {#if selected === 'prepaids'}
        <PrepaidsComponent /> 
        <PrepaidModal /> 
    {:else if selected === 'credits'}
        <CreditsComponent userId={$userStore._id} /> 
        <CreditModal /> 
    {:else if selected === 'accounts'}
        <AccountsComponent />
    {:else if selected === 'charges'}
        <ChargesComponent userId={$userStore._id}/> 
        <ChargeModal /> 
    {:else if selected === 'transactions'}
        <TransactionsComponent /> 
        <TransactionModal userId={$userStore._id}/> 
    {/if}

</Container>