<script>

    import { creditStore } from '../stores.js'

    import DateMethod from '../$methods/date.methods.js'


</script>

<div class="table-container">
    <table class="table is-fullwidth is-hoverable">
        <tbody>

            <tr>
                <th>Fecha</th>
                <td>{ DateMethod.dateTimeLarge($creditStore.created) }</td>
            </tr>
            <tr>
                <th>Estatus</th>
                <td>{ $creditStore.status === 'paid' ? 'Pagado' : 'Pendiente' }</td>
            </tr>
            <tr>
                <th>Procesador</th>
                <td>{ $creditStore.processor }</td>
            </tr>
            <tr>
                <th>Método</th>
                <td><b>{ $creditStore.method }</b></td>
            </tr>
            <tr>
                <th>Monto</th>
                <td>${ $creditStore.amount }</td>
            </tr>
            <tr>
                <th>Referencia</th>
                <td>{ $creditStore.reference }</td>
            </tr>
        </tbody>
    </table>
</div>