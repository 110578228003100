import Superagent from 'superagent'
import Storage from './storage.service'
import $logout from './$logout'

const { HOST_API } = _CONSTANTS_

export default {
    getCharges
}

function getCharges(query = {}) {
    return new Promise((resolve, reject) => {
        Superagent
           .get(`${HOST_API}/charges`)
           .set('access', Storage.getItem('access'))
           .query(query)
           .end((err, res) => {
               resolve(res.body)
           })
    })
}