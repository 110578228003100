<script>

    import { toast } from 'bulma-toast'
    import { modalStore, storesStore } from '../stores'
    
    import StoresService from '../$services/stores.service'
    import DateMethod from '../$methods/date.methods.js'
    
    import Spinner from '../$components/spinner.svelte'
    import Message from '../$components/message.svelte'
    import Delete from '../$components/delete.svelte'
    
    const { BRAND } = _CONSTANTS_

    let loading = false

    getStores()

    async function getStores() {

        loading = true
        const resp = await StoresService.getStores(null)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        storesStore.set(resp.data)
    }

    async function deleteStore(store) {

        loading = true
        const resp = await StoresService.deleteStore(store._id)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        storesStore.remove(store._id)
        toast({message: 'Tienda borrada', type: 'is-success'})
    }

    function openConfig(store) {
        switch(store.type) {
            case 'shopify': modalStore.set('shopify_config_modal'); break
            case 'woocommerce': modalStore.set('woocommerce_config_modal'); break
            case 'vtex': modalStore.set('vtex_config_modal'); break
        }
    }

</script>

<style>

    .store-items {
        display: flex;
        flex-wrap: wrap;
    }

    .store-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 32px;
        margin: 16px;
        border: solid 2px;
        border-radius: 8px;
        border-image-slice: 1;
        border-image-source: #006BE6;
    }

</style>

<div class="items-end">
    <button on:click={() => modalStore.set('integration_create_modal')} class="button">
        <span class="icon"><i class="fas fa-plus"></i></span>
        <span>Integración</span>
    </button>
</div>

<Spinner {loading} />
<Message show={!loading && $storesStore.length == 1} />

{#if !loading && $storesStore.length > 1}
    <div class="store-items">
        {#each $storesStore as store}
            {#if store.name != BRAND}
                <div class="store-item">
                    <div class="title is-5">{ store.name } <span class="tag is-primary">{ store.type }</span> </div>
                    <div class="subtitle is-6">{ store.domain }</div>
                    <div>{ DateMethod.dateLarge(store.created) }</div>
                        <div class="columns">
                            <div class="column">
                                <Delete on:confirm={() => deleteStore(store)} {loading} />
                            </div>
                            <div class="column">
                                <button on:click={() => modalStore.open('store', 'update_modal', store)} class="button is-fullwidth space-left">
                                    <span class="icon"><i class="fas fa-edit"></i></span>
                                    <span>Editar</span>
                                </button>
                            </div>
                            <div class="column">
                                <button on:click={() => openConfig(store)} class="button is-fullwidth space-left">
                                    <span class="icon"><i class="fas fa-cog"></i></span>
                                    <span>Configuración</span>
                                </button>
                            </div>
                        </div>
                    </div>
            {/if}
        {/each}
    </div>
{/if}