<script>

    import { toast } from 'bulma-toast'

    import ShipmentsService from '../$services/shipments.service'

    import Menu from '../$layouts/menu.layout.svelte'
    import Container from '../$layouts/container.layout.svelte'

    import GraphicsView from '../graphics/component.view.svelte'

    let shipmentsTotal = null
    let loading = false
    
    getShipmentsTotal()

    async function getShipmentsTotal() {

        loading = true
        const resp = await ShipmentsService.getShipmentsTotal()
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        shipmentsTotal = resp.data
    }

</script>

<Menu />

<Container>
    <GraphicsView />
</Container>