<script>

    import { toast } from 'bulma-toast'
    import { createEventDispatcher } from 'svelte'

    import QuotationsService from '../$services/quotations.service'
    import Spinner from '../$components/spinner.svelte'
    import Message from '../$components/message.svelte'
    import Button from '../$components/button.svelte'

    import TipRandom from '../tips/component.tip.random.svelte'
    import NumeralMethods from '../$methods/numeral.methods.js'

    const dispatch = createEventDispatcher()

    // requestAccess
    export let query = {}

    export let quotation = null
    export let pickup = null
    export let delivery = null
    export let ipackage = null
    export let localServices = false
    export let random = true
    export let shipmentType = 'national'
    export let loading = true
    
    let quotations = [] 
    
    getQuotations()

    async function getQuotations() {

        const data = {
            pickup,
            delivery,
            package: ipackage,
            localServices
        }

        let resp = null

        loading = true

        if(shipmentType === 'international')
            resp = await QuotationsService.getQuotationsInternational(data)
        
        if(shipmentType === 'national')
            resp = await await QuotationsService.getQuotations(data, query)

        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        quotations = resp.data
    }

    function onSelected(item) {
        quotation = item
        dispatch('selected', item)
    }
    
</script>

{#if loading}
    {#if random}
        <TipRandom />
    {/if}
    <Spinner {loading} />
{/if}

<Message show={!loading && !quotations.length} message="Sin cobertura" />

{#if !loading && quotations.length}
    <br>
    <div class="columns">
        <div class="column">
            <div class="title is-5">Origen</div>
            <div class="subtitle is-6">{ quotations[0].pickup.zipcode }, { quotations[0].pickup.state }</div>
        </div>
        <div class="column">
            <div class="title is-5">Destino</div>
            <div class="subtitle is-6">{ quotations[0].delivery.zipcode }, { quotations[0].delivery.state }</div>
        </div>
        <div class="column">
            <div class="title is-5">Paquete</div>
            <div class="subtitle is-6">{ ipackage.width }cm x { ipackage.height}cm x { ipackage.length }cm, { ipackage.weight }kg</div>
        </div>
    </div>
    <br>
    {#each quotations as quotation}
        <div class="columns">
            <div class="column">
                <img src="/images/{ quotation.provider }.svg" alt="{ quotation.provider }" style="max-height: 50px;">
            </div>
            <div class="column">
                <div class="title is-5">{ quotation.provider.replace('Partner', '') }</div>
                <div class="subtitle is-6">
                    { quotation.service }
                    {#if quotation.isCoverageCost}
                        <span class="tag is-warning">Zona extendida</span>
                    {/if}
                    {#if !quotation.isDeliveryHome}
                        <span class="tag is-danger">Entrega ocurre</span>
                    {/if}
                    {#if quotation.information}
                        <br>
                        <small>{ quotation.information }</small>
                    {/if}
                </div>
            </div>
            <div class="column">
                <div class="title is-5">{ NumeralMethods.cash(quotation.finalPrice) }</div>
                <div class="subtitle is-6">{ quotation.days } Días</div>
            </div>
            <div class="column">
                <Button on:click={() => onSelected(quotation)} text="Continuar" icon="arrow-alt-circle-right" color="primary" />
            </div>
        </div>
    {/each}
{/if}