<script>

    import { toast } from 'bulma-toast'
    import { shopifysStore } from '../stores'

    import ShopifyService from '../$services/shopify.service'
    import DateMethods from '../$methods/date.methods'
    import Spinner from '../$components/spinner.svelte'
    import Delete from '../$components/delete.svelte'

    let loading = false

    getShopifyApps()

    async function getShopifyApps() {
    
        loading = true
        const resp = await ShopifyService.getShopifyApps()
        loading = false
    
        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})
    
        shopifysStore.set(resp.data)
    }

    async function deleteShopifyApp(shopify) {
    
        loading = true
        const resp = await ShopifyService.deleteShopifyApp(shopify._id)
        loading = false
    
        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})
    
        shopifysStore.remove(shopify._id)
        toast({message: 'La solicitud de integración ha sido borrada', type: 'is-success'})
    }

</script>

<style>

    .store-items {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    .store-item {
        min-width: calc(50% - 32px);
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 32px;
        margin: 16px;
        border: solid 2px #FEFEFE;
        border-radius: 16px;
    }

    .image {
        max-width: 100px;
        padding: 16px;
    }

</style>

<Spinner {loading} />

{#if $shopifysStore.length}
    <hr>
    <div class="title is-5">Integraciones shopify pendientes</div>
    <div class="store-items">
        {#each $shopifysStore as shopify}
            <div class="store-item">
                <img class="image" src="images/shopify.svg" alt="shopify">
                <div class="title is-5">{ shopify.domain }</div>
                <div class="subtitle is-6">{ shopify.status === 'success'? 'Link de instalación listo' : 'Generando link de instalación' }</div>
                <div>{ DateMethods.dateTimeLarge(shopify.created) }</div>
                <div class="items-end space-top">
                    {#if shopify.status === 'success'}
                        <a class="button is-primary space-left" href="{shopify.link}" target="_blank">
                            <span class="icon"><i class="fas fa-link"></i></span>
                            <span>Instalar aplicación</span>
                        </a>
                    {/if}
                </div>
            </div>
        {/each}
    </div>
{/if}