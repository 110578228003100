<script>

    import UsersLoginComponent from './component.login.svelte'
    import RecoveryModal from './modal.recovery.svelte'

</script>

<style>

    .container {
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .content {
        border: solid 2px #FEFEFE;
        border-radius: 16px;
        min-width: 30vw;
        z-index: 5;
    }

    .head {
        background: #222;
        color: #FEFEFE;
        border-radius: 16px 16px 0 0;
        padding: 32px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
 
    .title {
        color: #FEFEFE !important;
        margin: 0 !important;
    }

    .body {
        padding: 32px;
    }

    @media screen and (max-width: 420px) {
        .container {
            align-items: flex-start;
        }
        .content {
            width: 100vw;
            border: none;
            border-radius: none;
        }
        .head {
            border-radius: 0;
        }
    }

</style>

<RecoveryModal />

<div class="container">
    <div class="content">
        <div class="head">
            <div class="title">
                <img style="max-height: 80px;" src="/images/brand_transparent.png" alt="YaVaEnvio">
                Ya Va Envío
            </div>
        </div>
        <div class="body">
            <UsersLoginComponent />
        </div>
    </div>
</div>