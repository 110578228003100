<script>

    import { createEventDispatcher } from 'svelte'
    import Tooltip from './tooltip.svelte'

    const dispatch = createEventDispatcher()

    export let label = ''
    export let icon = ''
    export let value = ''
    export let placeholder = ''
    export let iconType = 'fa'
    export let iconBase = 'fas'
    export let tooltip = null
    export let maxlength = null
    export let minlength = null
    export let required = true
    export let disabled = false
    export let readonly = false
    export let whitesmoke = false

    let isValid = true

    if(value)
        verifyText()

    function verifyText() {

        if(maxlength && minlength)
            return isValid = (value.length >= minlength && value.length <= maxlength) ? false : true

        if(maxlength)
            return isValid = (value.length > 0  && value.length <= maxlength) ? false : true

        if(minlength)
            return isValid = value.length >= minlength ? false : true
        
        return isValid = false
    
    }

</script>

<style>
    .class-whitesmoke {
        color: #FEFEFE;
    }
</style>

<div class="field">
    {#if label}
        <label for="" class="label" class:class-whitesmoke={ whitesmoke}>{ label }</label>
    {/if}
    <div class="control " class:has-icons-left={ icon }>
        {#if icon}
            <span class="icon"><i class="{ iconBase } { iconType }-{ icon }"></i></span>
        {/if}
        {#if tooltip}
            <Tooltip message={tooltip}>
                <input on:keyup={ e => e.code === 'Enter' && dispatch('enter') } on:keyup={ verifyText } on:click on:keyup on:focus on:blur bind:value={ value } class="input" type="text" { disabled } { readonly } { maxlength } { minlength } { placeholder } { required } class:is-invalid={ required && isValid } >
            </Tooltip>
        {:else}
            <input on:keyup={ e => e.code === 'Enter' && dispatch('enter') } on:keyup={ verifyText } on:click on:keyup on:focus on:blur bind:value={ value } class="input" type="text" { disabled } { readonly } { maxlength } { minlength } { placeholder } { required } class:is-invalid={ required && isValid } >
        {/if}
    </div>
</div>