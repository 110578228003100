<script>

    import { toast } from 'bulma-toast'
    import { transactionsStore, modalStore } from '../stores.js'

    import TransactionsService from '../$services/transactions.service'

    import DateMethod from '../$methods/date.methods.js'
    import Spinner from '../$components/spinner.svelte'
    import Message from '../$components/message.svelte'

    export let userId = null

    let loading = false

    getTransactions()

    async function getTransactions() {

        loading = true
        const resp = await TransactionsService.getTransactions(userId)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        transactionsStore.set(resp.data)
    }

</script>

<Spinner {loading} />
<Message show={!loading && !$transactionsStore.length} />

{#if !loading && $transactionsStore.length} 

    <div class="table-container">
        <table class="table is-fullwidth is-hoverable">
            <thead>
                <tr>
                    <th></th>
                    <th>
                        <div class="icon-text">
                            <span class="icon">
                              <i class="fas fa-clipboard-check"></i>
                            </span>
                            <span>Estatus</span>
                        </div>
                    </th>
                    <th>
                        <div class="icon-text">
                            <span class="icon">
                              <i class="fas fa-clipboard-list"></i>
                            </span>
                            <span>Destinado</span>
                        </div>
                    </th>
                    <th>
                        <div class="icon-text">
                            <span class="icon">
                              <i class="fas fa-dollar-sign"></i>
                            </span>
                            <span>Monto</span>
                        </div>
                    </th>
                    <th>
                        <div class="icon-text">
                            <span class="icon">
                              <i class="fas fa-calendar"></i>
                            </span>
                            <span>Fecha</span>
                        </div>
                    </th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {#each $transactionsStore as transaction, index}
                    <tr>
                        <td>{ index+1 }</td>
                        <td>{ transaction.status === 'paid' ? 'Pagado' : 'Pendiente' }</td>
                        <td>{ transaction.destined === 'shipment' ? 'Envío' : 'prepaid' ? 'Prepago' : 'suscription' ? 'Membresia' : 'Cargo' }</td>
                        <td>${ transaction.amount }</td>
                        <td>{ DateMethod.dateTimeShort(transaction.created) }</td>
                        <td>
                            <button on:click={() => modalStore.open('transaction', 'modal', transaction)} class="button">
                                <span class="icon"><i class="fas fa-ellipsis-v"></i></span>
                            </button>
                        </td>
                    </tr>
                {/each}
            </tbody>
        </table>
    </div>
{/if}