<script>

    import { createEventDispatcher } from 'svelte'
    import { toast } from 'bulma-toast'

    import SatProductsService from '../$services/catalogs.service'
    import InputText from '../$components/input.text.svelte'

    const dispatch = createEventDispatcher()

    export let selected = {}

    let loading = false
    let hide = false
    let products = []
    
    async function getSatProducts() {

        hide = false
        selected._id = null
        selected.code = null

        loading = true
        const resp = await SatProductsService.getSatProducts({find: selected.name})
        loading = false
    
        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})
    
        products = resp.data
    }

    function onSelect(product) {
        hide = true
        selected = product
        dispatch('selected', product)
    }  

    function hideBox(value) {
        setTimeout(() => {
            hide = value
        }, 200)
    }

</script>

<style>
    .boxContainer {
        position: relative;
    }

    .boxSelect {
        max-width: 500px;
        max-height: 300px;
        overflow: auto;
        border-radius: 16px;
        background: hsl(0, 0%, 98%);
        border: solid 2px #FEFEFE;
        box-shadow: 0px 10px 11px -10px  hsl(0, 0%, 90%);
        position: absolute;
        z-index: 5;
    }

    .boxSelect.hide {
        display: none;
    }

    .item {
        padding: 8px 16px;
    }

    .item:hover {
        color: #FEFEFE;
        cursor: pointer;
        background: #EE6E2C;
        border-radius: 100px;
    }
</style>

<InputText 
    bind:value={ selected.name } 
    on:keyup={ getSatProducts } 
    on:focus={() => hideBox(false)} 
    on:blur={() => hideBox(true)} 
    label="Tipo de producto" 
    icon="qrcode" 
    placeholder="Tipo de producto" 
/>

<div class="boxContainer">
    <div class="boxSelect" class:hide={!products.length || selected._id || hide}>
        {#each products as product}
            <div on:click={() => onSelect(product)} class="item">{ product.name }</div>
        {/each}
    </div>
</div>

<div class="items-end">
    <div>{ selected.code || 'No se eligió un producto' } { selected.synonymous || '' }</div>
    {#if selected.warning != undefined && selected.warning != '0'}
        <div class="span icon" data-tooltip="Producto considerado peligroso"><i class="fas fa-exclamation-triangle"></i></div>
    {/if}
</div>