<script>

    import { replace } from 'svelte-spa-router'
    import { configStore } from '../stores'

    import StorageService from '../$services/storage.service'
    import ConfigsService from '../$services/configs.service'

    import Menu from '../$layouts/menu.layout.svelte'
    import Tabs from '../$components/tabs.svelte'
    import Delete from '../$components/delete.svelte'
    import Container from '../$layouts/container.layout.svelte'

    import User from '../users/component.user.svelte'
    import UserUpdateModal from '../users/modal.update.svelte'
    import UserUpdateEmail from '../users/component.update.email.svelte'
    import UserUpdatePassword from '../users/component.update.password.svelte'
    import UserUpdateInvoices from '../users/modal.update.invoices.svelte'
    import UserUpdateAutomatic from '../users/modal.update.automatic.svelte'

    import Locations from '../locations/component.locations.svelte'
    import LocationModal from '../locations/modal.location.svelte'
    import LocationCreateModal from '../locations/modal.create.svelte'
    import LocationUpdateModal from '../locations/modal.update.svelte'

    import Packages from '../packages/component.packages.svelte'
    import PackageModal from '../packages/modal.package.svelte'
    import PackageCreateModal from '../packages/modal.create.svelte'
    import PackageUpdateModal from '../packages/modal.update.svelte'

    import Merchandises from '../merchandises/component.merchandises.svelte'
    import MerchandiseModal from '../merchandises/modal.merchandise.svelte'
    import MerchandiseCreateModal from '../merchandises/modal.create.svelte'
    import MerchandiseUpdateModal from '../merchandises/modal.update.svelte'

    import ConfigEmail from './component.config.email.svelte'
    import PreviewEmailModal from './modal.preview.email.svelte'

    import ConfigTracking from './component.config.tracking.svelte'
    import ConfigLink from './component.config.link.svelte'

    import Integrations from '../integrations/view.integrations.svelte'
    import Accounts from '../accounts.parcels/view.accounts.svelte'

    export let params = {}

    getConfig()

    async function getConfig() {

        const resp = await ConfigsService.getConfig()

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        if(!resp.data.email)
            resp.data.email = {}

        if(!resp.data.tracking)
            resp.data.tracking = {}

        configStore.set(resp.data)
    }

    let selected = 'user'

    if(params.tab)
        selected = params.tab

    const tabsOptions = [
        {key: 'user', name: 'Usuario', icon: 'user'},
        {key: 'locations', name: 'Direcciones', icon: 'address-book'},
        {key: 'packages', name: 'Paquetes', icon: 'box-open'},
        {key: 'merchandises', name: 'Mercancias', icon: 'shopping-cart'},
        {key: 'emails', name: 'Correo', icon: 'envelope'},
        {key: 'smartlink', name: 'Smart Link', icon: 'link'},
        {key: 'trackings', name: 'Rastreo', icon: 'map-marker-alt'},
        {key: 'integrations', name: 'Integraciones', icon: 'shopping-bag'},
    ]

    function onLogout() {
        StorageService.deleteItem('user')
        StorageService.deleteItem('access')
        replace('#/')
    }

</script>

<Menu />

<Container>

    <Tabs bind:selected options={tabsOptions} />

    {#if selected === 'user'}
        <br>
        <div class="columns">
            <div class="column">
                <UserUpdateEmail />
            </div>
            <div class="column">
                <UserUpdatePassword />
            </div>
        </div>
        <div class="is-divider"></div>
        <div class="columns">
            <div class="column">
                <User />
            </div>
        </div>
        <div class="is-divider"></div>
        <div class="columns">
            <div class="column">
                <div class="items-end">
                    <div>
                        <Delete on:confirm={onLogout} text="Cerrar sesión" />
                    </div>
                </div>
            </div>
        </div>
    {:else if selected === 'locations'}
        <Locations />
    {:else if selected === 'packages'}
        <Packages />
    {:else if selected === 'merchandises'}
        <Merchandises />
    {:else if selected === 'smartlink'}
        <ConfigLink />
    {:else if selected === 'emails'}
        <ConfigEmail />
    {:else if selected === 'trackings'}
        <ConfigTracking />
    {:else if selected === 'integrations'}
        <Integrations />
    {:else if selected === 'accounts'}
        <Accounts />
    {/if}

</Container>

<UserUpdateModal />
<UserUpdateInvoices />
<UserUpdateAutomatic />

<LocationCreateModal />
<LocationModal />
<LocationUpdateModal />

<MerchandiseCreateModal />
<MerchandiseModal />
<MerchandiseUpdateModal />

<PackageCreateModal />
<PackageModal />
<PackageUpdateModal />
<PreviewEmailModal />