import Superagent from 'superagent'
import Storage from './storage.service'
import $logout from './$logout'

const { HOST_API } = _CONSTANTS_

export default {
    getCredits
}

function getCredits(query = {}) {
    return new Promise((resolve, reject) => {
        Superagent
            .get(`${HOST_API}/credits`)
            .set('access', Storage.getItem('access'))
            .query(query)
            .end((err, res) => {
                    $logout(res.body)
                    resolve(res.body)
            })
    })
}