<script>
    
    import { userStore, modalStore } from '../stores'

    import Value from '../$components/value.svelte'
    import Button from '../$components/button.svelte'

    function getText(value) {
        switch(value) {
            case 'P01': return 'Por definir'
            case 'G03': return 'Gastos en general'
            case '99': return 'Por definir'
            case '04': return 'Tarjeta de crédito'
            case '28': return 'Tarjeta de débito'
            case '03': return 'Transferencia electrónica de fondos'
        }
    }

</script>

<div class="title">Información personal</div>
<div class="columns">
    <div class="column">
        <Value label="Nombre(s)" value={ $userStore.firstName } />
    </div>
    <div class="column">
        <Value label="Apellido(s)" value={ $userStore.lastName || 'Sin información' } />
    </div>
</div>
<div class="columns">
    <div class="column">
        <Value label="Empresa" value={ $userStore.corporate || 'Sin información' } />
    </div>
    <div class="column">
        <Value label="Teléfono" value={ $userStore.phone } />
    </div>
</div>
<div class="items-end">
    <Button on:click={() => modalStore.set('user_update_modal') } text="Editar" icon="edit" />
</div>

<div class="is-divider"></div>
<div class="title">Correos automáticos</div>
<div class="columns">
    <div class="column">
        <Value label="Enviar correo de entrega al remitente" value={ $userStore.sendEmailDeliveryPickup ? 'SI' : 'NO' } />
    </div>
    <div class="column">
        <Value label="Enviar correo de entrega al destinatario" value={ $userStore.sendEmailDeliveryDelivery ? 'SI' : 'NO' } />
    </div>
</div>
<div class="items-end">
    <Button on:click={() => modalStore.set('user_update_automatic_modal') } text="Editar" icon="edit" />
</div>

{#if $userStore.adviser && $userStore.adviser.email} 
    <div class="is-divider"></div>
    <div class="columns">
        <div class="column">
            <Value label="Asesor" value={ $userStore.adviser.name } />
        </div>
        <div class="column">
            <Value label="Teléfono" value={ $userStore.adviser.phone } />
        </div>
    </div> 
    <div class="columns">
        <div class="column">
            <Value label="Correo electrónico" value={ $userStore.adviser.email } />
        </div>
    </div> 
{/if}