<script>
    
    import { toast } from 'bulma-toast'
    import { modalStore, orderLinkStore } from '../stores'

    import LinksService from '../$services/orders.links.service'

    import Form from '../$components/form.svelte'
    import Select from '../$components/select.svelte'
    import Button from '../$components/button.svelte'
    import InputCopy from '../$components/input.copy.svelte'

    import PackageSelect from '../packages/component.select.package.svelte'
    import LocationSelect from '../locations/component.select.location.svelte'

    orderLinkStore.set(null)

    const { HOST_APP } = _CONSTANTS_

    let providerSelect = ''
    let loading = false
    let data = {
        providers: []
    }

    async function createLink() {

        loading = true
        const resp = await LinksService.createLink(data)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        orderLinkStore.set(resp.data)
    }

    function addProvider() {

        if(!providerSelect)
            return

        data.providers = [...data.providers, providerSelect]
    }

    function removeProvider(index) {
        data.providers = data.providers.filter((item, i) => i != index)
    }

</script>

{#if !$orderLinkStore}
    <Form on:submit={ createLink } { loading }>

        <div class="columns">
            <div class="column">
                <Select bind:value={ data.type } icon="tag" label="El usuario puede" options={[
                    {value: 'basic', text: 'Guardar su información'},
                    {value: 'quotation', text: 'Cotizar su envío'},
                    {value: 'payment', text: 'Pagar su envío'},
                ]} />
            </div>
        </div>

        {#if data.type && data.type != 'basic'}
            
            <div class="title">Proveedores</div>
            <div class="columns">
                <div class="column">
                    <Select bind:value={ providerSelect } icon="tag" required={false} options={[
                        {value: 'Estafeta', text: 'Estafeta'},
                        {value: 'Redpack', text: 'Redpack'},
                        {value: 'FedEx', text: 'FedEx'},
                        {value: 'DHL', text: 'DHL'},
                        {value: '99Minutos', text: '99Minutos'},
                        {value: 'Paquetexpress', text: 'Paquetexpress'},
                        {value: 'JTExpress', text: 'JTExpress'},
                    ]} />
                </div>
                <div class="column is-narrow">
                    <Button on:click={ addProvider } text="Agregar" icon="plus" />
                </div>
            </div>
            <div class="columns">
                <div class="column">
                   {#if data.providers.length} 
                        <div class="table-container">
                            <table class="table is-fullwidth">
                                <tbody>
                                    {#each data.providers as provider, index}
                                        <tr>
                                            <td>
                                                <Button on:click={() => removeProvider(index)} icon="trash" /> { provider }
                                            </td>
                                        </tr>
                                    {/each}
                                </tbody>
                            </table>
                        </div>
                    {:else}
                        <div class="notification">Todos los proveedores</div>
                    {/if}
                </div>
            </div>
            
            <div class="columns">
                <div class="column">
                    <div class="title">Paquete</div>
                    <PackageSelect bind:ipackage={ data.package } showSelectValue />
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <div class="title">Origen</div>
                    <LocationSelect bind:location={ data.pickup } />
                </div>
            </div>
        {/if}

        <div class="items-end">
            <div class="buttons">
                <Button on:click={() => modalStore.set(null)} type="button" text="Cancelar" icon="ban" />
                <Button type="submit" text="Guardar" icon="save" color="primary" />
            </div>
        </div>

    </Form>
{/if}

{#if $orderLinkStore}
    <div class="columns">
        <div class="column">

            <div class="title">Link generado</div>
            <InputCopy value="{ HOST_APP }/#/orders/links/{ $orderLinkStore._id }" />
            
            {#if data.type === 'payment'}
                <div class="notification is-warning">El link sólo se puede usar una vez.</div>
            {/if}
        </div>
    </div>
{/if}