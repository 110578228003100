<script>

    import { modalStore } from '../stores.js'

    import Modal from '../$components/modal.svelte'
    import TransactionComponent from './component.transaction.svelte'

    $: show = $modalStore === 'transaction_modal'
    
</script>


<Modal title="Transacción" {show}>

    <TransactionComponent />
    
</Modal>