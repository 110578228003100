<script>

    import { toast } from 'bulma-toast'
    import { creditsStore, modalStore } from '../stores.js'

    import CreditsService from '../$services/credits.service'

    import DateMethod from '../$methods/date.methods.js'
    import Spinner from '../$components/spinner.svelte'
    import Message from '../$components/message.svelte'

    export let userId = null

    let query = {}
    let metadata = {}
    let loading = false

    getCredits()

    async function getCredits() {

        query.userId = userId

        loading = true
        const resp = await CreditsService.getCredits(query)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        creditsStore.set(resp.data.credits)
        metadata = resp.data.metadata
    }

    function page(type) {

        switch(type) {
            case 'next': query.page = metadata.next; break
            case 'previous': query.page = metadata.previous; break
        }

        getCredits()
    }

</script>

<Spinner {loading} />
<Message show={!loading && !$creditsStore.length} />

{#if !loading && $creditsStore.length} 
    <div class="items-space">
        <small>
            { $creditsStore.length } 
            {#if metadata.total}
                / {metadata.total}
            {/if}
            Resultados
        </small>
        <div class="field has-addons">
            {#if metadata.previous != null}
            <div class="control">
                <button on:click={() => page('previous')} class="button">
                    <span class="icon pointer"><i class="fas fa-angle-left"></i></span>
                </button>
            </div>
            {/if}
            {#if metadata.next != null}
                <div class="control">
                    <button on:click={() => page('next')} class="button">
                        <span class="icon pointer"><i class="fas fa-arrow-alt-circle-right"></i></span>
                    </button>
                </div>
            {/if}
        </div>
    </div>
    <div class="table-container">
        <table class="table is-fullwidth is-hoverable">
            <thead>
                <tr>
                    <th></th>
                    <th>
                        <div class="icon-text">
                            <span class="icon">
                              <i class="fas fa-clipboard-check"></i>
                            </span>
                            <span>Estatus</span>
                        </div>
                    </th>
                    <th>
                        <div class="icon-text">
                            <span class="icon">
                              <i class="fas fa-comments-dollar"></i>
                            </span>
                            <span>Método</span>
                        </div>
                    </th>
                    <th>
                        <div class="icon-text">
                            <span class="icon">
                              <i class="fas fa-clipboard-list"></i>
                            </span>
                            <span>Procesador</span>
                        </div>
                    </th>
                    <th>
                        <div class="icon-text">
                            <span class="icon">
                              <i class="fas fa-dollar-sign"></i>
                            </span>
                            <span>Monto</span>
                        </div>
                    </th>
                    <th>
                        <div class="icon-text">
                            <span class="icon">
                              <i class="fas fa-calendar"></i>
                            </span>
                            <span>Fecha</span>
                        </div>
                    </th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {#each $creditsStore as credit, index}
                    <tr>
                        <td>{ index+1 }</td>
                        <td>{ credit.status === 'paid' ? 'Pagado' : 'Pendiente' }</td>
                        <td>{ credit.method }</td>
                        <td>{ credit.processor }</td>
                        <td>${ credit.amount }</td>
                        <td>{ DateMethod.dateTimeShort(credit.created) }</td>
                        <td>
                            <button on:click={() => modalStore.open('credit', 'modal', credit)} class="button">
                                <span class="icon"><i class="fas fa-ellipsis-v"></i></span>
                            </button>
                        </td>
                    </tr>
                {/each}
            </tbody>
        </table>
    </div>
{/if}