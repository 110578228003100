<script>

    import { toast } from 'bulma-toast'
    import { replace } from 'svelte-spa-router'
    import { userStore, modalStore } from '../stores'

    import NoticesService from '../$services/notices.services'
    import UsersService from '../$services/users.service'

    import RecoveryModal from './modal.recovery.svelte'
    import Spinner from '../$components/spinner.svelte'

    let loading = false
    let data = {}

    async function login() {

        loading = true
        const resp = await UsersService.userLogin(data)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})
        
        userStore.set(resp.data.user)

        if(!resp.data.user.agreement)
            modalStore.set('agreement_important_modal')

        replace('#/home')
        getNotices()
    }

    async function getNotices() {

        const resp = await NoticesService.getNotices()

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        if(resp.data.length)
            modalStore.set('notice_modal')
    }

</script>

<Spinner {loading} />

{#if !loading}
    <form on:submit|preventDefault={login} autocomplete="off" >
        <div class="field">
            <label for=""  class="label">Correo</label>
            <div class="control has-icons-left" id="email">
                <span class="icon"><i class="fas fa-user"></i></span>
                <input bind:value={data.email} type="text" class="input" placeholder="Correo" required>
            </div>
        </div>
        <div class="field">
            <label for=""  class="label">Contraseña</label>
            <div class="control has-icons-left" id="email">
                <span class="icon"><i class="fas fa-lock"></i></span>
                <input bind:value={data.password} type="password" class="input" placeholder="Contraseña" required>
            </div>
        </div>
        <hr>
        <div class="items-center space-top">
            <button type="submit" class="button is-primary is-fullwidth">
                <span class="icon"><i class="fas fa-sign-in-alt"></i></span>
                <span>Iniciar Sesión</span>
            </button>        
        </div>
        <div class="items-center space-top">
            <button on:click={() => replace('#/signup')} type="button" class="button is-fullwidth">
                <span class="icon"><i class="fas fa-user-plus"></i></span>
                <span>Registrarme</span>
            </button>        
        </div>
        <div class="items-center space-top">
            <button on:click={() => modalStore.set('recovery_modal')} type="button" class="button is-inverted is-text is-fullwidth">
                <span class="icon"><i class="fas fa-user-lock"></i></span>
                <span>Ovide mi contraseña</span>
            </button>        
        </div>
    </form>
{/if}

<RecoveryModal />