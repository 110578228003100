<script>

    import { transactionStore } from '../stores.js'

    import DateMethod from '../$methods/date.methods.js'


</script>

<div class="table-container">
    <table class="table is-fullwidth is-hoverable">
        <tbody>

            <tr>
                <th>Fecha</th>
                <td>{ DateMethod.dateTimeLarge($transactionStore.created) }</td>
            </tr>
            <tr>
                <th>Estatus</th>
                <td>{ $transactionStore.status === 'paid' ? 'Pagado' : 'Pendiente' }</td>
            </tr>
            <tr>
                <th>Monto</th>
                <td>${ $transactionStore.amount }</td>
            </tr>
            <tr>
                <th>Destinado</th>
                <td>{ $transactionStore.destined === 'shipment' ? 'Envío' : 'prepaid' ? 'Prepago' : 'suscription' ? 'Membresia' : 'Cargo' }</td>
            </tr>
        </tbody>
    </table>
</div>