<script>

    import { toast } from 'bulma-toast'
    import { replace } from 'svelte-spa-router'

    import UsersService from '../$services/users.service'

    import Form from '../$components/form.svelte'
    import Input from '../$components/input.text.svelte'
    import Button from '../$components/button.svelte'

    let loading = false
    let data = {}

    async function createUserSingup() {

        loading = false
        const resp = await UsersService.createUserSingup(data)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        toast({message: 'Usuario registrado', type: 'is-success'})
        toast({message: 'Inicia sesión ahora', type: 'is-success'})
        replace('#/')
    }

</script>

<Form on:submit={ createUserSingup } {loading}>
    <div class="columns">
        <div class="column">
            <Input bind:value={ data.firstName } icon="user" label="Nombre" placeholder="Ingresa tu nombre" />
        </div>
    </div>
    <div class="columns">
        <div class="column">
            <Input bind:value={ data.lastName } icon="user" label="Apellido" placeholder="Ingresa tu apellido" />
        </div>
    </div>
    <div class="columns">
        <div class="column">
            <Input bind:value={ data.email } icon="envelope" label="Correo" placeholder="Ingresa tu correo" />
        </div>
    </div>
    <div class="columns">
        <div class="column">
            <Input bind:value={ data.phone } icon="phone" label="Teléfono" placeholder="Ingresa tu teléfono" />
        </div>
    </div>
    <div class="columns">
        <div class="column">
            <Input bind:value={ data.password } icon="lock" label="Contraseña" placeholder="Ingresa tu contraseña" />
        </div>
    </div>
    <hr>
    <div class="columns">
        <div class="column">
            <Button text="Registrarme" icon="user-plus" type="submit" color="primary" fullwidth />
        </div>
    </div>
    <div class="columns">
        <div class="column">
            <Button on:click={() => replace('#/')} icon="sign-in-alt" text="Iniciar Sesión" type="button" fullwidth />
        </div>
    </div>
</Form>