<script>

    import { toast } from 'bulma-toast'
    import { userStore, walletStore, notifyStore, modalStore } from '../stores'

    import WalletsService from '../$services/wallets.service'
    import NumeralMethods from '../$methods/numeral.methods'

    $: $notifyStore === 'wallet_updated' && getWallet() && notifyStore.set(null)

    let loading = false

    getWallet()

    async function getWallet() {

        if(!$userStore)
            return

        loading = true
        const resp = await WalletsService.getWallet(null)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        walletStore.set(resp.data || {})
    }

</script>

{#if !loading}
    <div on:click={() => modalStore.set('wallet_payment')} class="pointer" style="color: #FEFEFE;">
        <span class="icon"><i class="fas fa-wallet"></i></span>
        <span>{ NumeralMethods.cash($walletStore.amount) }</span>
    </div>
{/if}